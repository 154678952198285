/* @license
 *
 * © 2019–2021 Daniel Aleksandersen <https://www.daniel.priv.no/>
 * SPDX-License-Identifier: CC0-1.0
 */
(function() {
  function onloader(callback)
  {
    if (['complete', 'interactive'].includes(document.readyState))
    {
      callback();
    }
    else
    {
      document.addEventListener('DOMContentLoaded', callback);
    }
  }
  function uuidgen()
  {
    return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, function(substitute) {
      var char = Number.parseInt(substitute, 10);
      return (char ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> char / 4).toString(16);
    });
  }
  if (!['prerender', 'unloaded'].includes(document.visibilityState)) {
    window.ctrlpw = window.ctrlpw || {};
    window.ctrlpw.time = new Date();
    window.ctrlpw.pagesession = uuidgen();
    window.ctrlpw.pageUnloading = false;
    window.ctrlpw.timer = -1;
    window.ctrlpw.visibility = document.visibilityState;
    window.ctrlpw.origin = window.location.origin;
    window.ctrlpw.referrer = document.referrer.substr(0, 256);
    window.ctrlpw.colorscheme = window.matchMedia('(prefers-color-scheme:dark)').matches ? 'dark' : window.matchMedia('(prefers-color-scheme:light)').matches ? 'light' : 'default';
    if (typeof navigator.userAgentData != 'undefined')
    {
      (window.ctrlpw.agent = window.ctrlpw.agent || {}).ch = window.ctrlpw.agent.ch || {};
      if (typeof navigator.userAgentData.brands != 'undefined')
      {
        var brands = navigator.userAgentData.brands.map(
          function(brand) {
            return `"${brand.brand}";v="${brand.version}"`;
          }
        );
        if (typeof navigator.brave != 'undefined')
        {
          brands.push('Brave')
        }
        window.ctrlpw.agent.ch.ua = brands.sort().join(', ');
      }
      if (typeof navigator.userAgentData.platform != 'undefined')
      {
        window.ctrlpw.agent.ch.platform = navigator.userAgentData.platform;
      }
      if (typeof navigator.userAgentData.mobile != 'undefined')
      {
        window.ctrlpw.agent.ch.mobile = navigator.userAgentData.mobile;
      }
    }
    if (!window.ctrlpw.agent && typeof navigator.userAgent != 'undefined')
    {
      (window.ctrlpw.agent = window.ctrlpw.agent || {}).useragent = navigator.userAgent;
    }
    function tallyTimeOnPage()
    {
      if (isNaN(window.ctrlpw.timer) || window.ctrlpw.timer < 0)
      {
        window.ctrlpw.timer = 0;
      }
      if (!window.ctrlpw.time instanceof Date)
      {
        window.ctrlpw.time = new Date();
      }
      var time_now  = new Date(),
          new_state = document.visibilityState,
          old_state = window.ctrlpw.visibility;
      var start_timer = (
            (
              ['hidden', 'unloaded'].includes(old_state) ||
              typeof old_state === 'undefined'
            ) &&
            new_state == 'visible'
          ),
          stop_timer  = (
            window.ctrlpw.pageUnloading ||
            (
              ['hidden', 'unloaded'].includes(new_state) &&
              old_state == 'visible'
            )
          );
      if (start_timer)
      {
        window.ctrlpw.visibility = new_state;
        window.ctrlpw.time = time_now;
      }
      else if (stop_timer)
      {
        window.ctrlpw.visibility = new_state;
        window.ctrlpw.timer += (time_now - window.ctrlpw.time);
      }
    }
    document.addEventListener('visibilitychange', tallyTimeOnPage);
    function tallyPageLoadTime()
    {
      if (!performance instanceof Object)
      {
        return;
      }
      if (performance.getEntriesByType instanceof Function &&
          typeof performance.getEntriesByType('first-contentful-paint')[0] !== 'undefined')
      {
        window.ctrlpw.pageloadtime = performance.getEntriesByName('first-contentful-paint')[0].startTime + performance.getEntriesByName('first-contentful-paint')[0].duration;
      }
      else if (performance.getEntriesByType instanceof Function &&
               typeof performance.getEntriesByType('navigation')[0] !== 'undefined')
      {
        window.ctrlpw.pageloadtime = performance.getEntriesByType('navigation')[0].duration;
      }
      else if ('timing' in performance)
      {
        window.ctrlpw.pageloadtime = performance.timing.domContentLoadedEventEnd - performance.timing.navigationStart;
      }
      if (window.ctrlpw.pageloadtime <= 0 || window.ctrlpw.pageloadtime >= 6e+5)
      {
        delete window.ctrlpw.pageloadtime;
      }
    }
    function submitAnalytics(original_data, beacon)
    {
      var endpoint = 'https://l.ctrl.blog/visit';
      var data = Object.assign({}, original_data);
      delete data.time;
      delete data.pageUnloading;
      delete data.visibility;
      data = JSON.stringify(data);
      if (!beacon || !(navigator.sendBeacon instanceof Function && navigator.sendBeacon(endpoint, data)))
      {
        var req = new XMLHttpRequest();
        data.xhr = 1;
        req.open('POST', endpoint, true);
        req.setRequestHeader('Content-Type', 'text/plain;charset=utf-8');
        req.send(data);
        delete data.xhr;
      }
    }
    function analyticsCollector(ev)
    {
      window.ctrlpw.pageUnloading = true;
      tallyTimeOnPage();
      window.ctrlpw.timer = Math.round(window.ctrlpw.timer / 1000);  // to seconds
      if (window.ctrlpw.timer <= 1)
      {
        delete window.ctrlpw.timer;
      }
      tallyPageLoadTime();
      window.ctrlpw.pageloadtime = Math.round(window.ctrlpw.pageloadtime / 50) * 50;
      detectScriptBlocker();
      submitAnalytics(window.ctrlpw, true);
    }
    if (typeof window.onpagehide == 'object')
    {
      window.addEventListener('pagehide', analyticsCollector);
    }
    else if (typeof window.onbeforeunload == 'object')
    {
      window.addEventListener('beforeunload', analyticsCollector);
    }
    else
    {
      window.addEventListener('unload', analyticsCollector);
    }
    function canonicalPageURL()
    {
      var url = document.querySelector("link[rel~='canonical'][href]");
      if (url)
      {
        window.ctrlpw.page = new URL(url.href).pathname;
      }
      else
      {
        window.ctrlpw.page = window.location.pathname;
      }
    }
    onloader(canonicalPageURL);
    function measureWebMonetization()
    {
      (((window.ctrlpw = window.ctrlpw || {}).monetization = window.ctrlpw.monetization || {}).webmonetization = window.ctrlpw.monetization.webmonetization || {}).detected = typeof document.monetization !== 'undefined';
      if (window.ctrlpw.monetization.webmonetization.detected)
      {
        var script = document.createElement('script');
        script.scr = '/assets/script/webmonetization-303d7.js';
        script.defer = true;
        document.body.appendChild(script);
      }
    }
    onloader(measureWebMonetization);
    function detectClassBlocker()
    {
      window.ctrlpw.blocked = window.ctrlpw.blocked || {};
      var test = document.createElement('div');
      test.appendChild(document.createTextNode(' ')); // U+00A0 !
      test.className = 'adsbox';
      document.body.appendChild(test);
      window.setTimeout(
        function() {
          window.ctrlpw.blocked.adsbox_class = (test.offsetHeight == 0);
          test.parentNode.removeChild(test);
        }, 1000);
    }
    onloader(detectClassBlocker);
    function detectScriptBlocker()
    {
      if (! document.querySelector('.mm'))
      {
        return;
      }
      window.ctrlpw.blocked = window.ctrlpw.blocked || {};
      if (window.ctrlpw.blocked.script_load)
      {
        return;
      }
      var loaded = (window.adsbygoogle && window.adsbygoogle.loaded === true);
      var script_srchref = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      var script_element = document.querySelector(`script[src^="${script_srchref}"]`);
      if (script_element)
      {
        script_srchref = script_element.src;
      }
      if (loaded && performance.getEntriesByName instanceof Function)
      {
        if (!! performance.getEntriesByName(document.location)[0])
        {
          window.ctrlpw.blocked.script_load = ! performance.getEntriesByName(script_srchref)[0];
        }
      }
      if (typeof window.ctrlpw.blocked.script_load === 'undefined')
      {
        if (! loaded || ! document.querySelector('.adsbygoogle[data-adsbygoogle-status]'))
        {
          window.ctrlpw.blocked.script_load = true;
        }
      }
    }
    window.setTimeout(detectScriptBlocker, 4000);
    if (window.location.hash.startsWith('#src=') && window.location.hash.substr(5))
    {
      window.ctrlpw.source = window.location.hash.substr(5);
      if (!!window.history && !!window.history.replaceState)
      {
        history.replaceState(null, document.title, window.location.pathname);
      }
    }
    onloader(function() {
      setTimeout(function() {
        submitAnalytics(window.ctrlpw, false);
      }, 5000);
    });
  }
})();